import { Row } from '@shipt/design-system-layouts'
import { type SingleEntryForm as SingleFormType } from '@/features/cms/components/CMS/types'
import { RHFTextInput } from '@/features/shared/elements/Inputs/TextInput'
import { useForm } from 'react-hook-form'
import { Button } from '@shipt/design-system-buttons'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import { Body } from '@shipt/design-system-typography'
import { screenSizes } from '@shipt/design-system-themes'
import { singleEntryFormValidations } from '@/features/cms/components/CMS/utils'
import { z } from 'zod'
import { useRouter } from 'next/router'
import { routes } from '@shared/constants/routes'
import { useCMSTrackingEvents } from '@/features/cms/components/CMS/CMSContext'
import { isSafeSegwayURL } from '@/features/shared/utils/url'

type Props = {
  data: SingleFormType
}

type EmailForm = {
  email: string
}

/**
 * This component corresponds to the `single_entry_form` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/single_entry_form
 * - **Production** - https://cms.shipt.com/content-type/pool/single_entry_form
 */
export const SingleEntryForm = ({ data }: Props) => {
  const { trackCMSElementClicked } = useCMSTrackingEvents()
  const router = useRouter()
  const {
    input_field_placeholder,
    cta_link,
    disclaimer_text,
    validation_type = 'DEFAULT',
  } = data.data

  const formValueValidation: z.Schema<EmailForm> = z.object({
    email: z.string().refine(() => singleEntryFormValidations[validation_type]),
  })

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm<EmailForm>({
    resolver: zodResolver(formValueValidation),
  })

  const message_goal = () => {
    if (validation_type === 'STUDENT_EMAIL_VALIDATION')
      return 'get_a_shipt_student_membership_for_4.99'
    else return 'get_started'
  }

  const onSubmit = ({ email }: EmailForm) => {
    trackCMSElementClicked({
      content: 'get_started',
      type: 'button',
      message_goal: message_goal(),
    })

    const destination = `${
      cta_link?.url ?? routes.MEMBERSHIP_DASHBOARD.url
    }?student_email=${email}`

    if (isSafeSegwayURL(destination)) {
      router.push(destination)
    }
  }

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)} noValidate>
      <InputWrapper>
        <EmailInputWrapper>
          <RHFTextInput
            id="email"
            name="email"
            label={input_field_placeholder}
            control={control}
            defaultValue=""
            type="email"
            error={errors?.email?.message}
            onClear={() => resetField('email', { defaultValue: '' })}
          />
        </EmailInputWrapper>
        {cta_link?.text && (
          <SubmitButton type="submit">{cta_link.text}</SubmitButton>
        )}
      </InputWrapper>
      {disclaimer_text && (
        <Body variant="secondary" size="md">
          {disclaimer_text}
        </Body>
      )}
      {cta_link?.text && (
        <MobileButton type="submit" fullWidth>
          {cta_link.text}
        </MobileButton>
      )}
    </FormWrapper>
  )
}

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 37.5rem;
  width: 100%;

  @media ${screenSizes.max_mobile} {
    text-align: center;
  }
`
const InputWrapper = styled(Row)`
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;

  @media ${screenSizes.tablet} {
    padding-right: 0;
  }
`

const EmailInputWrapper = styled.div`
  flex: 1 0;

  @media ${screenSizes.tablet} {
    max-width: 20rem;
  }
`
const SubmitButton = styled(Button)`
  display: none;

  @media ${screenSizes.tablet} {
    display: block;
    max-width: 10rem;
    margin-bottom: 1.5rem;
  }
`
const MobileButton = styled(Button)`
  display: block;
  margin-top: 1.5rem;

  @media ${screenSizes.tablet} {
    display: none;
  }
`
