import { type SyntheticEvent, useState } from 'react'
import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'
import { isValidZip } from '@/features/shared/utils/validator'
import {
  sendDataToZapier,
  ZapierSheetId,
} from '@/features/entrypoint/components/Marketing/utils/zapier'
import { trackEnteredZipCode } from '@/features/shared/analytics/marketing'
import { AddressIcon } from '@shipt/design-system-icons'
import { useFetchShoppingStores } from '@/features/shop/services/ShoppingStore/queries'
import { useRouter } from 'next/router'
import { routes } from '@shared/constants/routes'
import { getUtmParams } from '@/features/shared/utils/url'
import { Button } from '@shipt/design-system-buttons'
import { trackElementClicked } from '@/features/shared/analytics/element'
import { TextInput } from '@shipt/design-system-inputs'
import { Body } from '@shipt/design-system-typography'
import { type ZipCheckData } from '@/features/cms/components/CMS/types'
import { useHandleDialog } from '@/features/shared/state/Dialog/useHandleDialog'
import { getCMSWrapperProps } from '@/features/cms/components/CMS/utils/getCMSWrapperProps'
import { DynamicZipNotCoveredModal } from '@/features/account/components/Modal/ZipNotCoveredModal/DynamicZipNotCoveredModal'

type ZipCheckFormProps = Partial<ZipCheckData> & { className?: string }

/**
 * This component corresponds to the `zip_check` content type in the CMS.
 *
 * - **Staging** - https://cms-staging.shipt.com/content-type/pool/zip_check
 * - **Production** - https://cms.shipt.com/content-type/pool/zip_check
 */
export const ZipCheckForm = ({
  className,
  content_type_id,
  id,
  data,
}: ZipCheckFormProps) => {
  const { openDialog } = useHandleDialog()
  const [zip, setZip] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const router = useRouter()
  const { button_text, disclaimer } = data ?? {}

  const { fetchStores, isLoading } = useFetchShoppingStores()

  const handleSendDataToZapier = (sheetId: ZapierSheetId) =>
    sendDataToZapier(sheetId, { _zip: zip, date: new Date() })

  const handleOpenZipNotCoveredModal = () => {
    handleSendDataToZapier(ZapierSheetId.u4h4k5)
    openDialog(DynamicZipNotCoveredModal, { zip })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    setZip(e.target.value)
  }

  const handleFormSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setErrorMessage('')
    trackEnteredZipCode(zip)
    trackElementClicked({
      type: 'button',
      content: button_text || 'find_stores_near_you',
      message_goal: 'membership_acquisition',
      display_format: 'horizontal_list',
      display_sublocation: 'hero',
    })
    if (!isValidZip(zip)) {
      return setErrorMessage('Please enter a valid zip code.')
    }

    try {
      const stores = await fetchStores({ address: { zip_code: zip } })
      if (!stores?.delivery_stores?.length)
        return handleOpenZipNotCoveredModal()
      router.push({
        pathname: routes.SIGN_UP.url,
        query: { zipcode: zip, ...getUtmParams() },
      })
      handleSendDataToZapier(ZapierSheetId.u4hfpf)
    } catch {
      handleOpenZipNotCoveredModal()
    }
  }

  return (
    <ZipWrapper
      className={className}
      {...(content_type_id &&
        id &&
        getCMSWrapperProps({ content_type_id, id }))}
    >
      <ZipForm
        name="zip-check-form"
        id="zip-check-form"
        onSubmit={handleFormSubmit}
      >
        <InputWrapper>
          <ZipCodeInput
            name="zip"
            aria-label="Enter your ZIP code"
            placeholder="Enter your ZIP code"
            value={zip}
            inputMode="numeric"
            maxLength={5}
            pattern="[0-9]*"
            autoComplete="off"
            onChange={(e) => handleChange(e)}
            leading={<AddressIcon />}
            errorMessage={errorMessage}
          />
        </InputWrapper>
        <SubmitButton
          type="submit"
          concept="accent"
          disabled={isLoading}
          loading={isLoading}
        >
          {button_text ? button_text : 'Find stores near you'}
        </SubmitButton>
      </ZipForm>
      {disclaimer && <Disclaimer size="sm">{disclaimer}</Disclaimer>}
    </ZipWrapper>
  )
}

export const ZipWrapper = styled.div`
  width: 100%;
`

export const ZipForm = styled.form`
  display: flex;
  margin: 0 auto;
  gap: 0.5rem;
  flex-flow: column wrap;

  @media ${screenSizes.smDesktop} {
    flex-flow: row wrap;
  }
`

const InputWrapper = styled.div`
  @media ${screenSizes.tablet} {
    max-width: 14.6875rem;
    flex: 1 0;
  }
`

const ZipCodeInput = styled(TextInput)`
  @media ${screenSizes.tablet} {
    input {
      width: 100%;
    }
  }
`

const SubmitButton = styled(Button)`
  align-self: baseline;

  @media ${screenSizes.max_mobile} {
    width: 100%;
    max-width: 100%;
  }
`

const Disclaimer = styled(Body)`
  margin-top: 1rem;

  @media ${screenSizes.smDesktop} {
    margin-top: 0;
  }
`
